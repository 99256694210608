<template>
  <div class="pb-8">
    <div class="mb-16 flex min-h-dvh flex-col">
      <SiteHeader class="flex-0" />

      <div class="flex flex-1 flex-col overflow-hidden pb-2 sm:pb-2 lg:pb-3">
        <div
          class="relative flex flex-1 flex-col overflow-hidden rounded-xl border-gray-800 dark:border"
        >
          <!-- class="relative mb-16 flex min-h-[100dvh] flex-col items-center justify-center overflow-hidden py-24" -->
          <div
            class="absolute inset-0 z-0 block bg-gray-800 after:absolute after:inset-0 after:z-0 after:block after:bg-black/75 after:content-['']"
          >
            <video
              id="background-video"
              class="size-full object-cover object-center blur"
              preload="none"
              data-autoplay=""
              loop="true"
              muted="true"
              volume="0"
              autoplay
              poster="@/assets/img/bg-preview.jpg"
            >
              <source src="@/assets/video/bg-video.webm" type="video/webm" />
              <source src="@/assets/video/bg-video.mp4" type="video/mp4" />
            </video>
          </div>

          <div
            class="wrap relative z-10 flex flex-1 items-center justify-center text-white"
          >
            <div class="content mx-auto max-w-screen-xl">
              <div
                class="align-items-center grid grid-cols-5 items-center gap-8"
              >
                <div class="col-span-3">
                  <h2
                    class="tagline am-heading mb-4 text-5xl font-thin drop-shadow"
                  >
                    Space in your space
                  </h2>
                  <p
                    class="description mb-12 text-2xl font-thin tracking-wide drop-shadow"
                  >
                    Posters, shirts, hoodies, and more featuring awe-inspiring
                    NASA space photos.
                  </p>
                  <p class="m-0 space-x-4">
                    <UButton
                      icon="i-fa6-solid-arrow-right"
                      to="/shop"
                      class="font-bold"
                    >
                      Shop Now
                    </UButton>

                    <UButton
                      icon="i-fa6-solid-rocket"
                      to="/new"
                      color="white"
                      class="border-none font-bold"
                    >
                      New Launches
                    </UButton>
                  </p>
                </div>

                <div class="col-span-2 text-center">
                  <img
                    src="@/assets/img/products/sweatshirt-black.png"
                    class="inline-block"
                    style="width: auto; max-height: 400px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mx-auto px-5 lg:px-0">
      <h2>Index</h2>

      <div>
        <div v-for="category in categories" :key="category.id" class="mb-24">
          <h3 class="mb-8 text-lg font-bold">
            <nuxt-link :to="'/shop/' + category.handle">{{
              category.title
            }}</nuxt-link>
          </h3>

          <CollectionGrid
            :path-root="'/shop/' + category.handle"
            :collections="category.subcollections.references.nodes"
          />
        </div>
      </div>

      <div class="grid-cols-2 place-items-stretch gap-6 py-5 lg:grid">
        <div
          class="h-100 mb-4 rounded border border-gray-900 p-8 lg:mb-0 shadow"
        >
          <p class="mb-2 text-4xl">
            <UIcon name="i-fa6-solid-leaf" />
          </p>
          <p class="m-0">
            <strong>Carbon Neutrality</strong>
          </p>
          <p class="m-0">Investing in programs that help the environment.</p>
        </div>

        <div class="mb-4 rounded border border-gray-900 p-8 lg:mb-0 shadow">
          <p class="mb-2 text-4xl">
            <UIcon name="i-fa6-solid-shield-halved" />
          </p>
          <p class="m-0">
            <strong>Secure Payments</strong>
          </p>
          <p class="m-0">100% secure payments.</p>
        </div>

        <div
          class="h-100 mb-4 rounded border border-gray-900 p-8 lg:mb-0 shadow"
        >
          <p class="mb-2 text-4xl">
            <UIcon name="i-fa6-solid-earth-americas" />
          </p>
          <p class="m-0">
            <strong>Worldwide Shipping</strong>
          </p>
          <p class="m-0">
            Available in most regions with standard and select express shipping.
          </p>
        </div>

        <div
          class="h-100 mb-4 rounded border border-gray-900 p-8 lg:mb-0 shadow"
        >
          <p class="mb-2 text-4xl">
            <UIcon name="i-fa6-solid-user-group" />
          </p>
          <p class="m-0">
            <strong>Super Service</strong>
          </p>
          <p class="m-0">Hassle-free returns and friendly customer service.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const store = useStore();
const { shop } = storeToRefs(store);
const { baseUrl } = useRuntimeConfig().public;

const query = gql`
  query ($handle: String) {
    collection(handle: $handle) {
      id
      subcollections: metafield(namespace: "custom", key: "subcollections") {
        references(first: 30) {
          nodes {
            ... on Collection {
              id
              handle
              title
              subcollections: metafield(
                namespace: "custom"
                key: "subcollections"
              ) {
                references(first: 6) {
                  nodes {
                    ... on Collection {
                      id
                      title
                      handle
                      image {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const variables = { handle: "categories" };
const { data } = await useAsyncQuery("categories", { query, variables });

if (!data.value) navigateTo("/404");

// Computed
const categories = computed(
  () => data.value?.collection?.subcollections?.references?.nodes ?? []
);

definePageMeta({
  pageTransition: false,
  layoutTransition: false,
});

onMounted(() => {});

useJsonld({
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: shop?.value?.name,
  url: baseUrl,
  potentialAction: {
    "@type": "SearchAction",
    target: "https://astronomerch.com/search?q={search_term_string}",
    "query-input": "required name=search_term_string",
  },
});

useJsonld({
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Astronomerch LLC",
  alternateName: "Astronomerch",
  url: baseUrl,
  logo: new URL("/assets/logo.png", baseUrl).toString(),
  sameAs: [
    "https://x.com/astronomerch",
    "https://instagram.com/astronomerch",
    "https://facebook.com/astronomerch",
    "https://www.youtube.com/@astronomerch",
    "https://www.linkedin.com/company/astronomerch",
    baseUrl,
  ],
});

useHead(() => ({
  link: [
    {
      rel: "canonical",
      href: baseUrl,
    },
  ],
}));

useSeoMeta({
  description: shop?.value.description,
  ogTitle: shop?.value.name,
  ogDescription: shop?.value.description,
});
</script>

<style scoped>
section {
  /* min-height: 100vh;
padding: 60px 0; */

  min-height: 100dvh;
  /* min-height: -webkit-fill-available; */
}

section.contact {
  /* height: 600px; */
  /* min-height: auto; */
}

pre {
  transform: scale(0.6);
  transform-origin: center right;
  border-radius: 100%;
}

.blink {
  animation: blink 0.02s linear infinite;
  animation-iteration-count: 45;
}

@keyframes blink {
  0%,
  50% {
    opacity: 0;
  }
  51%,
  100% {
    opacity: 1;
  }
}
</style>
